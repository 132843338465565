import style from './list-item.module.scss'
import { Link } from 'react-router-dom'

/*

  ListItem:
    - In the future, allow these to have collapsable sub-listitems

*/
export const ListItem: React.FC<any> = (props: { title: string; active: boolean; Icon: React.FC; link?: string }) => {
  const { Icon } = props
  return (
    <div data-active={props.active} className={style.listItem}>
      {props.link ? (
        <Link to={props.link}>
          <Icon />
          <p>{props.title}</p>
        </Link>
      ) : (
        <div>
          <Icon />
          <p>{props.title}</p>
        </div>
      )}
    </div>
  )
}
