// Project Dependencies
import { AuthService } from './auth.service'
import { io, ManagerOptions, Socket, SocketOptions } from 'socket.io-client'
import axios from 'axios'

export interface IConnection {
  email: string
  name: string
  id: string
  group_id: string
}

export interface IOverlay extends IConnection {
  scenes: { name: string; dataFormat: any; buttons: string[] }[]
}

export interface IPlugin extends IConnection {
  rate: number
  active: boolean
  ingame_match_guid: string // Used for failover
}

export class WebsocketService {
  io: Socket
  url: string = ''
  server_id: string = ''
  loggedIn: boolean = false
  info: { name: string; author: string; version: string } | null = null

  constructor(private auth: AuthService, server?: string, opts?: Partial<ManagerOptions & SocketOptions>) {
    this.io = io({
      autoConnect: false,
    })
    if (server) {
      this.url = server
      this.connect(server, opts)
    }
  }

  async login(offline: boolean = false) {
    return new Promise((resolve, reject) => {
      if (this.loggedIn) return resolve(undefined)
      if (!this.io.connected) {
        this.loggedIn = false
        return reject(new Error('Not connected.'))
      }

      if (offline) {
        this.io.emit('login', 'CONTROLBOARD')
        resolve(true)
      } else {
        this.io.emit('login', 'CONTROLBOARD', (path: string) => {
          axios({
            method: 'post',
            url: `${this.url}${path}`,
            data: {
              token: this.auth.getToken(),
              type: 'CONTROLBOARD',
            },
          })
            .then((val) => {
              resolve(val)
            })
            .catch((err) => {
              reject(err)
            })
        })
      }
    })
  }

  connect(server: string, opts?: Partial<ManagerOptions & SocketOptions>) {
    this.disconnect()
    this.io = io(server, opts)
    this.url = server
  }

  disconnect() {
    this.url = ''
    this.loggedIn = false
    if (this.io.connected) {
      this.io.disconnect()
    }
  }

  disconnectClient(sid: string, cb: (err?: Error) => void) {
    if (this.loggedIn) {
      this.io.emit('relay:deactivate', sid, cb)
    } else {
      cb(new Error('Not logged in.'))
    }
  }

  sendSceneData(group_id: string, scene: string, data: any) {
    if (this.loggedIn) this.io.emit('scene:update_data', group_id, scene, data)
  }

  unassign(sid: string, cb: (err?: Error) => void) {
    this.assign(sid, 'Unassigned', cb)
  }

  assign(sid: string, match_id: string, cb: (err?: Error) => void) {
    if (this.loggedIn) {
      this.io.emit('relay:assign', sid, match_id, cb)
    } else {
      cb(new Error('Not logged in.'))
    }
  }

  execute(group: string, scene: string, name: string) {
    if (this.loggedIn) {
      this.io.emit('scene:execute', group, scene, name)
    }
  }

  getGroups(cb: (groups: string[]) => void) {
    if (this.loggedIn) {
      this.io.emit('groups:list', cb)
    } else cb([])
    return
  }

  getAllConnections(cb: (connections: any[]) => void) {
    if (this.loggedIn) {
      this.io.emit('connection:list', (cons: any[]) => {
        cb(cons)
      })
    } else {
      cb([])
    }
  }

  getConnections(
    type: 'PLUGIN' | 'OVERLAY' | 'CONTROLBOARD',
    cb: (connections: { id: string; [key: string]: any }[], err?: Error) => void,
  ) {
    if (this.loggedIn) {
      this.io.emit(`connection:list`, (cons: any[]) => {
        cb(cons.filter((x) => x.type === type))
      })
    } else cb([], new Error('Not logged in'))
    /*cb([
      {
        email: 'ben@nylund.us',
        name: 'Test Connection',
        id: 'SOCKET_ID',
        match_id: 'Unassigned',
        scenes: [
          {
            name: 'Test Scene',
            dataFormat: {
              match_title: 'String',
              background: 'String',
              isStreamed: 'Boolean',
              testElms: 'String[]',
              testNums: 'Number[]',
              game: {
                name: 'String',
                series: 'Number',
                TeamOne: {
                  name: 'String',
                  color: 'String',
                  teamnum: 'Number',
                },
              },
              teams: [
                {
                  name: 'String',
                  series: 'Number',
                  players: [
                    {
                      name: 'String',
                      score: 'Number',
                      platforms: [
                        {
                          name: 'String',
                          id: 'String',
                        },
                      ],
                    },
                  ],
                },
              ],
              match: 'Match[]',
            },
          },
          {
            name: 'Test Scene #2',
            dataFormat: {
              match_title: 'String',
              background: 'String',
              isStreamed: 'Boolean',
              testElms: 'String[]',
              testNums: 'Number[]',
              game: {
                name: 'String',
                series: 'Number',
                TeamOne: {
                  name: 'String',
                  color: 'String',
                  teamnum: 'Number',
                },
              },
              teams: [
                {
                  name: 'String',
                  series: 'Number',
                  players: [
                    {
                      name: 'String',
                      score: 'Number',
                      platforms: [
                        {
                          name: 'String',
                          id: 'String',
                        },
                      ],
                    },
                  ],
                },
              ],
              match: 'Match',
            },
          },
          /*{
            name: 'Test Scene #3',
            dataFormat: {
              match_title: 'String',
              background: 'String',
              isStreamed: 'Boolean',
              testElms: 'String[]',
              testNums: 'Number[]',
              game: {
                name: 'String',
                series: 'Number',
                TeamOne: {
                  name: 'String',
                  color: 'String',
                  teamnum: 'Number',
                },
              },
              teams: [
                {
                  name: 'String',
                  series: 'Number',
                  players: [
                    {
                      name: 'String',
                      score: 'Number',
                      platforms: [
                        {
                          name: 'String',
                          id: 'String',
                        },
                      ],
                    },
                  ],
                },
              ],
              match: 'Match',
            },
          },
          {
            name: 'Test Scene #4',
            dataFormat: {
              match_title: 'String',
              background: 'String',
              isStreamed: 'Boolean',
              testElms: 'String[]',
              testNums: 'Number[]',
              game: {
                name: 'String',
                series: 'Number',
                TeamOne: {
                  name: 'String',
                  color: 'String',
                  teamnum: 'Number',
                },
              },
              teams: [
                {
                  name: 'String',
                  series: 'Number',
                  players: [
                    {
                      name: 'String',
                      score: 'Number',
                      platforms: [
                        {
                          name: 'String',
                          id: 'String',
                        },
                      ],
                    },
                  ],
                },
              ],
              match: 'Match',
            },
          },
          {
            name: 'Test Scene #5',
            dataFormat: {
              match_title: 'String',
              background: 'String',
              isStreamed: 'Boolean',
              testElms: 'String[]',
              testNums: 'Number[]',
              game: {
                name: 'String',
                series: 'Number',
                TeamOne: {
                  name: 'String',
                  color: 'String',
                  teamnum: 'Number',
                },
              },
              teams: [
                {
                  name: 'String',
                  series: 'Number',
                  players: [
                    {
                      name: 'String',
                      score: 'Number',
                      platforms: [
                        {
                          name: 'String',
                          id: 'String',
                        },
                      ],
                    },
                  ],
                },
              ],
              match: 'Match',
            },
          },
        ],
      },
      {
        email: 'ben@nylund.us',
        name: 'Test Connection 1',
        id: 'SOCKET_ID 2',
        match_id: 'MATCH #1',
        scenes: [],
      },
      {
        email: 'ben@nylund.us',
        name: 'Test Connection 2',
        id: 'SOCKET_ID 3',
        match_id: 'MATCH #1',
        scenes: [],
      },
      {
        email: 'ben@nylund.us',
        name: 'Test Connection 3',
        id: 'SOCKET_ID 4',
        match_id: 'Unassigned',
        scenes: [],
      },
      {
        email: 'ben@nylund.us',
        name: 'Test Connection 4',
        id: 'SOCKET_ID 5',
        match_id: 'Unassigned',
        scenes: [],
      },
      {
        email: 'ben@nylund.us',
        name: 'Test Connection 5',
        id: 'SOCKET_ID 6',
        match_id: 'Unassigned',
        scenes: [],
      },
      {
        email: 'ben@nylund.us',
        name: 'Test Connection 6',
        id: 'SOCKET_ID 7',
        match_id: 'Unassigned',
        scenes: [],
      },
      {
        email: 'ben@nylund.us',
        name: 'Test Connection 7',
        id: 'SOCKET_ID 8',
        match_id: 'Unassigned',
        scenes: [],
      },
      {
        email: 'ben@nylund.us',
        name: 'Test Connection8 ',
        id: 'SOCKET_ID 9',
        match_id: 'Unassigned',
        scenes: [],
      },
      {
        email: 'ben@nylund.us',
        name: 'Test Connection 9',
        id: 'SOCKET_ID 10',
        match_id: 'Unassigned',
        scenes: [],
      },
      {
        email: 'ben@nylund.us',
        name: 'Test Connection 10',
        id: 'SOCKET_ID 11',
        match_id: 'Unassigned',
        scenes: [],
      },
      {
        email: 'ben@nylund.us',
        name: 'Test Connection',
        id: 'SOCKET_ID',
        match_id: 'Unassigned',
        scenes: [],
      },
      {
        email: 'ben@nylund.us',
        name: 'Test Connection',
        id: 'SOCKET_ID',
        match_id: 'Unassigned',
        scenes: [],
      },
      {
        email: 'ben@nylund.us',
        name: 'Test Connection',
        id: 'SOCKET_ID',
        match_id: 'Unassigned',
        scenes: [],
      },
      {
        email: 'ben@nylund.us',
        name: 'Test Connection',
        id: 'SOCKET_ID',
        match_id: 'Unassigned',
        scenes: [],
      },
      {
        email: 'ben@nylund.us',
        name: 'Test Connection',
        id: 'SOCKET_ID',
        match_id: 'Unassigned',
        scenes: [],
      },
    ])*/
    return
  }
}
