import style from './avatar.module.scss'
import React, { useState } from 'react'
import { Tooltip } from '@mui/material'

/*

  Avatar:
    - We will pass in a user object instead of url/_id
    when the auth system gets setup. What should a click
    event produce? a dropdown?

*/
export const Avatar: React.FC<any> = (props: {
  url: string
  _id: string
  size: 'SMALL' | 'MEDIUM' | 'LARGE'
  tooltip?: boolean
  onClick?: Function
}) => {
  if (props.url === null || props._id === null) return null
  return (
    <Tooltip title={props.tooltip ? props._id : ''} arrow>
      <div data-size={props.size} className={style.avatar} onClick={(e) => (props.onClick ? props.onClick(e) : null)}>
        <img src={props.url} />
      </div>
    </Tooltip>
  )
}
