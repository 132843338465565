import style from './loading.module.scss'
import logo from '../../assets/logo.png'
import { LinearProgress, Stack } from '@mui/material'
import { useServices, useWebsocket } from '../../hooks/services'
import { useEffect, useState } from 'react'
import { useTitle } from '../../hooks/title'

export const Loading: React.FC<any> = (props: { server: string; onLoggedIn: () => void }) => {
  const { websocket, server: serverService } = useServices()
  const [status, setStatus] = useState('Retrieving server info...')
  const [error, setError] = useState<string | null>(null)
  useTitle('Connecting to server...')

  useEffect(() => {
    const handlers: any = {}

    serverService
      .getServer(props.server, true)
      .then((val) => {
        setStatus('Connecting to server...')

        if (!val.instance || !val.instance.State || val.instance!.State!.Name !== 'running') {
          setError("Server not running! Double check the status of the server in your organization's dashboard.")
          return val
        }

        websocket.connect(`https://${props.server}.nylund.us`, {
          autoConnect: false,
          timeout: 10000,
          transports: ['websocket'],
        })

        handlers['info'] = (info: any) => {
          websocket.info = info
        }

        handlers['connect'] = () => {
          setStatus('Logging in...')
          websocket
            .login()
            .then((val) => {
              setStatus('Logged in!')

              setTimeout(() => {
                websocket.server_id = props.server
                websocket.loggedIn = true
                props.onLoggedIn()
              }, 1000)
            })
            .catch((err) => {
              setError(err.message)
            })
        }

        handlers['connect_error'] = (err: any) => {
          setError('Failed to connect.')
          websocket.io.close()
        }

        websocket.io.on('connect', handlers['connect'])
        websocket.io.on('connect_error', handlers['connect_error'])
        websocket.io.on('info', handlers['info'])

        websocket.io.connect()
      })
      .catch((err) => {
        setError('Failed to get server information. Try refreshing the page.')
      })

    return () => {
      websocket.io.removeListener('info', handlers['info'])
      websocket.io.removeListener('connect', handlers['connect'])
      websocket.io.removeListener('connect_error', handlers['connect_error'])
    }
  }, [true])

  return (
    <div className={style.loading}>
      <img src={logo} width={200} />
      <Stack sx={{ width: '80%', color: 'grey.500', marginTop: '50px' }} spacing={2}>
        {error === null ? (
          <>
            <p>{status}</p>
            {status === 'Logged in!' ? (
              <LinearProgress variant="determinate" value={100} color="secondary" />
            ) : (
              <LinearProgress color="primary" />
            )}
          </>
        ) : (
          <>
            <p>{error}</p>
            <LinearProgress variant="determinate" color="error" value={100} />
          </>
        )}
      </Stack>
    </div>
  )
}
