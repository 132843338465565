import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Tooltip } from '@mui/material'

export const Explainer: React.FC<any> = (props: { title: string; body: string }) => {
  return (
    <Tooltip
      arrow
      title={
        <>
          <p style={{ fontSize: '20px' }}>{props.title}</p>
          <hr />
          <p>{props.body}</p>
        </>
      }
      componentsProps={{
        tooltip: {
          style: {
            fontSize: '14px',
            backgroundColor: '#fff',
            color: 'black',
            textAlign: 'center',
            border: '1px solid black',
            padding: '10px',
          },
        },
        arrow: {
          style: {
            color: 'white',
            filter: 'drop-shadow(0px 0px 0.4px black)',
          },
        },
      }}
    >
      <HelpOutlineIcon style={{ verticalAlign: 'middle', marginLeft: '5px', marginBottom: '2px' }} fontSize={'small'} />
    </Tooltip>
  )
}
