import { get as LSGet, set as LSSet, remove as LSRemove } from 'local-storage'
import { LoadingButton } from '@mui/lab'
import { yupResolver } from '@hookform/resolvers/yup'
import { TextField } from '@mui/material'
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import history from 'history/browser'
import * as yup from 'yup'

import { useTitle } from '../../hooks/title'
import style from './login.module.scss'
import bg from '../../assets/login_background.jpg'
import logo from '../../assets/logo.png'
import { useAuth } from '../../hooks/services'

export const Unauthenticated = (cur: string, navigate: NavigateFunction) => {
  LSRemove('token')
  LSRemove('user')
  LSSet('postlogin', cur)
  navigate('/login', { replace: true })
}

// thank u mr. stackoverflow
export function getEmailRegex() {
  return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
}

const loginSchema = yup
  .object({
    email: yup.string().matches(getEmailRegex()).required(),
    password: yup.string().min(8).required(),
  })
  .required()

const signupSchema = yup
  .object({
    email: yup.string().matches(getEmailRegex()).required(),
    password: yup.string().min(8).required(),
    firstname: yup.string().min(1).required(),
    lastname: yup.string().min(1).required(),
  })
  .required()

function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export const Login: React.FC<any> = () => {
  const auth = useAuth()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  })

  const {
    register: sRegister,
    handleSubmit: sHandleSubmit,
    formState: { errors: sErrors },
  } = useForm({
    resolver: yupResolver(signupSchema),
  })

  const [locals, setLocals] = useState({ loading: false, error: false, step: 0 })
  const navigate = useNavigate()
  const query = useQuery()
  const s = query.get('s')
  useTitle('Login')

  const onLogin = (data: any) => {
    setLocals({ ...locals, loading: true })
    auth
      .login(data)
      .then((user) => {
        const to: string = LSGet('postlogin') ?? '/dashboard'
        LSRemove('postlogin')
        navigate(to, { replace: true })
      })
      .catch((err) => {
        console.log(err)
        setLocals({ ...locals, error: true, loading: false })
      })
  }

  const onSignup = (data: any) => {
    setLocals({ ...locals, loading: true })
    auth
      .signup(data)
      .then((user) => {
        LSRemove('postlogin')
        navigate('/dashboard', { replace: true })
      })
      .catch((err) => {
        console.log(err)
        setLocals({ ...locals, error: true, loading: false })
      })
  }

  useEffect(() => {
    if (auth.getUser().loggedIn && auth.getToken().length > 0) {
      const to: string = LSGet('postlogin') ?? '/dashboard'
      LSRemove('postlogin')
      navigate(to, { replace: true })
    } else {
      // Clear current local storage
      auth.logout()
    }

    if (s && !Number.isNaN(s) && Number(s) >= 0 && Number(s) <= 2) {
      setLocals({ ...locals, step: Number(s) })
    } else {
      setLocals({ ...locals, step: 0 })
    }

    let unlisten = history.listen(({ location }) => {
      const params = new URLSearchParams(location.search)
      const ns = params.get('s')
      if (ns && !Number.isNaN(ns) && Number(ns) >= 0 && Number(ns) <= 2) {
        setLocals({ ...locals, step: Number(ns) })
      } else {
        setLocals({ ...locals, step: 0 })
      }
    })

    return () => {
      unlisten()
    }
  }, [query.get('s')])

  if (locals.step < 0 || locals.step > 2) {
    setLocals({ ...locals, step: 0 })
  }

  return (
    <div className={style.login}>
      <img src={bg} />
      <div>
        <div className={style.loginBox}>
          <div>
            <img src={logo} alt="Logo" />
            <div className={style.loginContent}>
              {locals.step === 0 ? (
                <>
                  <h3>Login</h3>
                  {locals.error ? <p>Invalid credentials.</p> : null}
                  <form key="login-email-pass" onSubmit={handleSubmit(onLogin)}>
                    <TextField
                      {...register('email', { required: true, pattern: getEmailRegex() })}
                      helperText={errors.email ? 'Invalid email.' : ''}
                      error={Boolean(errors.email)}
                      variant="outlined"
                      label="Email"
                      name="email"
                    />
                    <TextField
                      {...register('password', { required: true, minLength: 8 })}
                      helperText={errors.password ? 'Invalid password.' : ''}
                      error={Boolean(errors.password)}
                      variant="outlined"
                      label="Password"
                      type="password"
                      name="password"
                    />
                    <p
                      onClick={() => {
                        history.push('/login?s=2')
                        history.go(1)
                        setLocals({ ...locals, step: 2 })
                      }}
                    >
                      Forgot your password?
                    </p>
                    <LoadingButton loading={locals.loading} variant="contained" type="submit">
                      Login
                    </LoadingButton>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <p style={{ marginRight: '5px', fontSize: '14px' }}>No account?</p>
                      <p
                        style={{ color: '#444', cursor: 'pointer', fontSize: '14px' }}
                        onClick={() => {
                          history.push('/login?s=1')
                          history.go(1)
                          setLocals({ ...locals, step: 1 })
                        }}
                      >
                        Sign up here
                      </p>
                    </div>
                  </form>
                </>
              ) : locals.step === 1 ? (
                <>
                  <h3>Sign up</h3>
                  {locals.error ? <p>Failed to sign up.</p> : null}
                  <form key="signup-email-pass" onSubmit={sHandleSubmit(onSignup)}>
                    <TextField
                      {...sRegister('firstname', { required: true, minLength: 1 })}
                      helperText={sErrors.firstname ? 'Invalid first name.' : ''}
                      error={Boolean(sErrors.firstname)}
                      variant="outlined"
                      label="First Name"
                      name="firstname"
                    />
                    <TextField
                      {...sRegister('lastname', { required: true, minLength: 1 })}
                      helperText={sErrors.lastname ? 'Invalid last name.' : ''}
                      error={Boolean(sErrors.lastname)}
                      variant="outlined"
                      label="Last Name"
                      name="lastname"
                    />
                    <TextField
                      {...sRegister('email', { required: true, pattern: getEmailRegex() })}
                      helperText={sErrors.email ? 'Invalid email.' : ''}
                      error={Boolean(sErrors.email)}
                      variant="outlined"
                      label="Email"
                      name="email"
                    />
                    <TextField
                      {...sRegister('password', { required: true, minLength: 8 })}
                      helperText={sErrors.password ? 'Invalid password.' : ''}
                      error={Boolean(sErrors.password)}
                      variant="outlined"
                      label="Password"
                      type="password"
                      name="password"
                    />
                    <LoadingButton loading={locals.loading} variant="contained" type="submit">
                      Sign up
                    </LoadingButton>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <p style={{ marginRight: '5px', fontSize: '14px' }}>Already have an account?</p>
                      <p
                        style={{ color: '#444', cursor: 'pointer', fontSize: '14px' }}
                        onClick={() => {
                          history.push('/login?s=0')
                          history.go(1)
                          setLocals({ ...locals, step: 0 })
                        }}
                      >
                        Log in here
                      </p>
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <h3>Forgot your password?</h3>
                  <p>Form here</p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
